/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes, { string, shape } from 'prop-types';
import { components } from 'ls-common-client';

const { Container, Text } = components;

const GaAccounts = props => {
  const { gaAccounts } = props;

  // console.log('GMBAccounts props');
  // console.log(props);

  return (
    <Container display="block" marginTop="10px" justifyContent="left">
      <Text
        textAlign="left"
        backgroundColor="#eaefff"
        padding="10px"
        display="block"
        marginBottom="10px"
        style={{ backgroundColor: '#eaefff' }}
      >
        <b>GA Accounts:</b>
      </Text>
      <Container
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
        }}
      >
        {/* <img
          alt="Google My Business"
          height="50px"
          width="50px"
          src="images/GMBLogo.PNG"
        /> */}
        <ul>
          {gaAccounts.map(i => (
            <li key={i.name} style={{ textAlign: 'left', width: '500px' }}>
              <GaAccount accountName={i.displayName} name={i.name} />
            </li>
          ))}
        </ul>
      </Container>
    </Container>
  );
};

GaAccounts.propTypes = {
  gaAccounts: PropTypes.arrayOf(
    shape({
      accountName: string,
      name: string,
    })
  ),
};

GaAccounts.defaultProps = {
  gaAccounts: [],
};

const GaAccount = props => {
  const { accountName, name } = props;

  return (
    <>
      <Text>{accountName}</Text>
    </>
  );
};

GaAccount.propTypes = {
  accountName: PropTypes.string,
  name: PropTypes.string,
};

GaAccount.defaultProps = {
  accountName: null,
  name: null,
};

export default GaAccounts;
