import config from '../../config';

const {
  api: { baseUrl },
} = config;

// cant get envs to work.. so hardcoding while in dev for now
// const baseUrl=`http://localhost:3000`;

const get = async () => {
  const response = await fetch(`${baseUrl}/v1/grader`, {
    method: 'GET',
  });
  const body = await (response || {}).json();
  return body || {};
};

const testDB = async () => {
  const response = await fetch(`${baseUrl}/v1/grader/testDB`, {
    method: 'GET',
  });
  const body = await (response || {}).json();
  return body || {};
};

const fGenerateAuthUrl = async () => {
  const response = await fetch(`${baseUrl}/v1/grader/fGenerateAuthUrl`, {
    method: 'GET',
  });
  const body = await (response || {}).json();
  return body || {};
};

const performAuth = async (googleCode, scopingData) => {
  console.log('googleCode: ');
  console.log(googleCode);
  const reqBody = {
    googleCode, // googlecallback code from auth
    scopingData, // any data from the form
  };
  const response = await fetch(`${baseUrl}/v1/grader/performAuth`, {
    method: 'POST',
    body: JSON.stringify(reqBody),
    headers: { 'Content-Type': 'application/json' },
  });

  const body = await (response || {}).json();
  console.log('bodyresponse');
  console.log(body);
  return body || {};
};

export { get, fGenerateAuthUrl, performAuth, testDB };
