/* eslint-disable */
import React from 'react';
import PropTypes, { string, shape } from 'prop-types';
import { components } from 'ls-common-client';
import { GmbLocations } from '.';

const { Container, Text } = components;

const GmbAccounts = props => {
  const { gmbAccounts } = props;

  // console.log('GMBAccounts props');
  // console.log(props);

  return (
    <Container display="block" marginTop="10px" justifyContent="left">
      <Text
        textAlign="left"
        backgroundColor="#eaefff"
        padding="10px"
        display="block"
        marginBottom="10px"
        style={{ backgroundColor: '#eaefff' }}
      >
        <b>GMB Accounts:</b>
      </Text>
      <Container
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
        }}
      >
        {/* <img
          alt="Google My Business"
          height="50px"
          width="50px"
          src="images/GMBLogo.PNG"
        /> */}
        <ul>
          {gmbAccounts.map(i => (
            <li key={i.name} style={{ textAlign: 'left', width: '500px' }}>
              <GmbAccount
                accountName={i.accountName}
                accountType={i.type}
                gmbLocations={i.gmbLocations}
                name={i.name}
              />
            </li>
          ))}
        </ul>
      </Container>
    </Container>
  );
};

GmbAccounts.propTypes = {
  gmbAccounts: PropTypes.arrayOf(
    shape({
      accountName: string,
      name: string,
      accountType: string,
      gmbLocations: PropTypes.arrayOf(
        PropTypes.shape({ locationName: string })
      ),
    })
  ),
};

GmbAccounts.defaultProps = {
  gmbAccounts: [],
};

const GmbAccount = props => {
  const { accountName, name, accountType, gmbLocations } = props;

  return (
    <>
      <Text>{accountName} ({accountType})</Text>
      <GmbLocations gmbLocations={gmbLocations} />
    </>
  );
};

GmbAccount.propTypes = {
  accountName: PropTypes.string,
  name: PropTypes.string,
  accountType: PropTypes.string,
  gmbLocations: PropTypes.arrayOf(PropTypes.shape({ locationName: string })),
};

GmbAccount.defaultProps = {
  accountName: null,
  name: null,
  accountType: null,
  gmbLocations: null,
};

export default GmbAccounts;
