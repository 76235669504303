import React from 'react';
import { components } from 'ls-common-client';
import { localsearch } from '../images';
import { splash } from '../animations';

const { Container, Animation, Image } = components;

const Splash = props => (
  <Container
    display="flex"
    flexDirection="column"
    backgroundColor="background500"
    alignItems="center"
    justifyContent="space-evenly"
    height="1px"
    minHeight="100%"
    {...props}
  >
    <Container flex="1 1 100%" display="flex">
      <Image
        width="250px"
        src={localsearch}
        alt="Localsearch Reviews Manager"
      />
    </Container>
    <Animation flex="0 0 300px" data={splash} />
    <Container flex="1 1 100%" />
  </Container>
);

export default Splash;
