/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { components } from 'ls-common-client';

import { Page, PageContent, PageHeader } from '../../../common';
import { AppContext } from '../../../../context';
import Splash from '../../../Splash';
import { Gmb } from '../../../local';

const qs = require('qs');

//  import { Redirect } from 'react-router-dom';

const { Container, Text } = components;

const StyledContainer = styled(Container)`
  text-align: center;
  border-radius: 11px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  background: #ffffff;
  padding: 20px;
`;

const CallbackFromGoogle = req => {
  const { setLoading, loading } = useContext(AppContext.Context);
  const uniqueClientId = qs.parse(req.location.search, {
    ignoreQueryPrefix: true,
  }).state;
  // console.log('uniqueClientIdAA:');
  // console.log(uniqueClientId);
  const googleCode = qs.parse(req.location.search, {
    ignoreQueryPrefix: true,
  }).code;
  // console.log(googleCode);

  // const scopingData = {
  //   uniqueClientId,
  //   testfield: 'crikey',
  // };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Page title="Google Callback">
      {loading ? (
        <Splash />
      ) : (
        <PageContent>
          <StyledContainer>
            <PageHeader />
            <Text fontSize="20px" textAlign="left">
              Thank you
            </Text>
            <Gmb googleCode={googleCode} scopingKey={uniqueClientId} />
          </StyledContainer>
        </PageContent>
      )}
    </Page>
  );
};

/* <Text fontSize="15px" textAlign="left">
Unique Client Id:
{uniqueClientId}
</Text>
<br />
<br />
<Gmb googleCode={googleCode} scopingKey={uniqueClientId}/> */

export default CallbackFromGoogle;
