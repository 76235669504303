import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Context = React.createContext(null);

const Provider = ({ children }) => {
  const [isHuman, setIsHuman] = useState();
  const [loading, setLoading] = useState(true);

  return (
    <Context.Provider
      value={{
        isHuman,
        setIsHuman,
        loading,
        setLoading,
      }}
    >
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Context, Provider };
