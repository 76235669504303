/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes, { string } from 'prop-types';
import { components } from 'ls-common-client';
import { GmbAccounts, GaAccounts } from '.';
import { onboardService } from '../../services';
import { loader } from '../../animations';

const { Container, Text, Animation } = components;

const Gmb = props => {
  const [show, setShow] = useState(false);
  const [preamble, setPreamble] = useState(
    'Please wait while we load your GMB data...'
  );
  const [userEmail, setUserEmail] = useState('');
  const [userPicture, setUserPicture] = useState('');
  const [data, setData] = useState({});
  const [didLoad, setDidLoad] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      //console.log("calling performAuth: "+props.googleCode);
      const authResult = await onboardService.performGMBAuth(
        props.googleCode,
        props.scopingKey
      );
      setPreamble('');
      setShow(true);
      setUserEmail(authResult.userEmail);
      setUserPicture(authResult.userPicture);
      setData(authResult);
    };

    if (didLoad) {
      //console.log
      fetchData();
      setDidLoad(false);
    }
    //   (async () => {
    //     // console.log('sendingqry');
    //     // console.log('authResult');
    //   })();
    // }
  });

  return (
    <Container>
      <Container
        display="flex"
        justifyContent="center"
        style={{ display: show ? 'none' : 'block' }}
      >
      </Container>
    </Container>
  );
};


// <Text justifyContent="center">{preamble}</Text>
// <br />
// <Animation justifyContent="center" data={loader} width="80px" />
// </Container>
// <Container style={{ display: show ? 'block' : 'none' }}>
// <Container display="block" justifyContent="left">
//   <Text
//     textAlign="left"
//     backgroundColor="#eaefff"
//     padding="10px"
//     display="block"
//     marginBottom="10px"
//     style={{ backgroundColor: '#eaefff' }}
//   >
//     <b>Google Account Info:</b>
//   </Text>
//   <Container
//     style={{
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//     }}
//   >
//     <img
//       alt="Google Profile"
//       height="50px"
//       width="50px"
//       src={userPicture}
//     />
//     <Text marginLeft="10px"> {userEmail}</Text>
//   </Container>
// </Container>
// <GmbAccounts gmbAccounts={data.gmbAccounts} />
// {/* <GaAccounts gaAccounts={data.gaAccounts} /> */}


Gmb.propTypes = {
  googleCode: PropTypes.string,
  scopingKey:  PropTypes.string,    //PropTypes.shape({}),
};

Gmb.defaultProps = {
  googleCode: null,
  scopingKey: null,
  // scopingData: {
  //   uniqueClientId: PropTypes.number,
  //   testfield: PropTypes.string,
  // },
};

export default Gmb;
