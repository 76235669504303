import config from '../../config';

const {
  api: { baseUrl },
} = config;

// console.log("env: ");
// console.log(env);

// console.log("bGGGGGGGGGaseurl: ");
// console.log(config);
// console.log("fgfg");

// cant get envs to work.. so hardcoding while in dev for now
// const baseUrl2=`http://localhost:3000`;
console.log(config);
const createScoping = async formFields => {
  // console.log("inside function:");
  // console.log(formFields);
  // const reqBody = {
  //   formFields, // any data from the form
  // };
  // console.log("env2: ");
  // console.log(env);

  // console.log("baseurl2: ");
  // console.log(baseUrl);
  // scopingData
  const response = await fetch(`${baseUrl}/v1/onboard`, {
    // const response = await fetch(`${baseUrl}/v1/onboard`, {
    method: 'POST',
    // body: JSON.stringify(reqBody),
    body: JSON.stringify(formFields),
    headers: { 'Content-Type': 'application/json' },
  });

  const body = await (response || {}).json();
  console.log('bodyresponse');
  console.log(body);
  return body || {};
};

const performGMBAuth = async (googleCode, scopingKey) => {
  // console.log('googleCode: ');
  // console.log(googleCode);
  // console.log('scopingKey: ');
  // console.log(scopingKey);
  const reqBody = {
    googleCode, // googlecallback code from auth
    scopingKey, // any data from the form
  };
  const response = await fetch(`${baseUrl}/v1/onboard/performGMBAuth`, {
    method: 'POST',
    body: JSON.stringify(reqBody),
    headers: { 'Content-Type': 'application/json' },
  });

  const body = await (response || {}).json();
  // console.log('bodyresponse');
  // console.log(body);
  return body || {};
};

export { createScoping, performGMBAuth };
