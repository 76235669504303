const { NODE_ENV: env = 'local', PORT: port = 8080 } = process.env;

module.exports = {
  env,
  port,
  debug: true,
  mode: 'development',
  sourceMap: true,
  hot: true,
  title: 'Localsearch Onboarding',
};
