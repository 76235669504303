import React from 'react';
import PropTypes, { string, shape } from 'prop-types';
import { components } from 'ls-common-client';

const { Container, Text } = components;

const GmbLocations = props => {
  const { gmbLocations } = props;

  const buildAddress = address => {
    let formattedAddress = '';
    if (address.addressLines) {
      if (address.addressLines.length > 0) {
        formattedAddress += `${address.addressLines[0]}, `;
      }
    }
    if (address.locality) {
      formattedAddress += `${address.locality}, `;
    }

    if (address.administrativeArea) {
      formattedAddress += `${address.administrativeArea} `;
    }

    if (address.postalCode) {
      formattedAddress += `${address.postalCode} `;
    }
    return formattedAddress;
  };

  console.log('GMBLocations props');
  console.log(props);

  if (gmbLocations.length > 0) {
    return (
      <Container display="block" marginTop="10px" justifyContent="left">
        <Text
          textAlign="left"
          padding="10px"
          width="100%"
          display="block"
          marginBottom="10px"
          style={{ backgroundColor: '#faf8d4' }}
        >
          <b>GMB Locations:</b>
        </Text>
        <Container
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
          }}
        >
          {/* <img
          alt="Google My Business"
          height="50px"
          width="50px"
          src="images/GMBLogo.PNG"
          /> */}
          <ul>
            {gmbLocations.map(i => (
              <li key={i.name} style={{ textAlign: 'left' }}>
                <GmbLocation
                  locationName={i.locationName}
                  name={i.name}
                  address={buildAddress(i.address)}
                />
              </li>
            ))}
          </ul>
        </Container>
      </Container>
    );
  }
  return <></>;
};

GmbLocations.propTypes = {
  gmbLocations: PropTypes.arrayOf(
    shape({
      locationName: string,
      name: string,
      address: string,
    })
  ),
};

GmbLocations.defaultProps = {
  gmbLocations: [],
};

const GmbLocation = props => {
  // eslint-disable-next-line no-unused-vars
  const { locationName, name, address } = props;

  return (
    <>
      <Text>{locationName}</Text>
      <ul>
        <Text>{address}</Text>
      </ul>
    </>
  );
};

GmbLocation.propTypes = {
  locationName: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
};

GmbLocation.defaultProps = {
  locationName: null,
  name: null,
  address: null,
};

export default GmbLocations;
