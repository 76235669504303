/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { components, hooks } from 'ls-common-client';

import { Page, PageContent, PageHeader } from '../../common';
import { AppContext } from '../../../context';
import { gmbGraderService, onboardService } from '../../../services';
import Splash from '../../Splash';
import defaultconfig from '../../../config';

//  import { Redirect } from 'react-router-dom';

const { Container, Text, Input, Button, Validator } = components;
const { useForm } = hooks;
const StyledContainer = styled(Container)`
  text-align: center;
  border-radius: 11px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  background: #ffffff;
  padding: 20px;
`;

const StyledForm = styled(Container)`
  display: flex;
  flex-direction: column;
  .checkbox {
    width: 150px;
    max-width: 150px;
  }
`;

const { gmbGrader } = defaultconfig;
// console.log(gmbGrader);

const getUniqueId = () => {
  const now = new Date();
  let id = now.getFullYear().toString(); // 2011
  id += (now.getMonth < 9 ? '0' : '') + now.getMonth().toString(); // JS months are 0-based, so +1 and pad with 0's
  id += (now.getDate < 10 ? '0' : '') + now.getDate().toString();
  id += (now.getHours < 10 ? '0' : '') + now.getHours().toString();
  id += (now.getMinutes < 10 ? '0' : '') + now.getMinutes().toString();
  id += (now.getSeconds < 10 ? '0' : '') + now.getSeconds().toString();
  return id;
};

const uniqueClientId = getUniqueId();
// const Home = () => {

const Home = req => {
  const { setLoading, loading } = useContext(AppContext.Context);

  useEffect(() => {
    setLoading(false);
  }, []);

  const qs = require('qs');

  const qsAbn = qs.parse(req.location.search, {
    ignoreQueryPrefix: true,
  }).abn;

  const qsName = qs.parse(req.location.search, {
    ignoreQueryPrefix: true,
  }).name;

  // console.log("name= "+qsName);

  const defaults = {
    name: qsName,
    abn: qsAbn,
  };

  // const onClick = async () => {
  //   // //const response = await gmbGraderService.get();
  //   // //const response = await gmbGraderService.getUserConsent();
  //   console.log('aaa');
  //   Window.location =
  //     'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fplus.business.manage&response_type=code&client_id=151553610018-i3vh0beu3qj89io7duir5nbqa53pe37b.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Foauth2callback';
  //   // //console.log('response', response);
  //   console.log('bbb');
  // };

  const onClick = async () => {
    // //const response = await gmbGraderService.get();
    // console.log("yyy");

    // console.log("clicked");

    const response = await gmbGraderService.fGenerateAuthUrl(); // this is the one that works

    // const response = await gmbGraderService.testDB();
    // console.log('responddddd');
  };

  // const onSubmit2 = async () => {
  //   // //const response = await gmbGraderService.get();
  //   // console.log("yyy");
  //   //const response = await gmbGraderService.fGenerateAuthUrl();
  //   //const response = await gmbGraderService.testDB();
  //   // console.log('responddddd');
  // };
  // const serverAuthUrl =
  //  'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fplus.business.manage&response_type=code&client_id=151553610018-i3vh0beu3qj89io7duir5nbqa53pe37b.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fv1%2Fgrader%2Foauth2callback';

  // GMB only
  // was working  const clientAuthUrl ='https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusiness.manage%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&client_id=596586744291-viv0dtkpvfalgec4ehotu00qv3hdnd42.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2FCallbackFromGoogle';

  const clientAuthUrl = gmbGrader.buttonUrl;
  // console.log('clientAuthUrl:');
  // console.log(clientAuthUrl);
  // GA and GMB
  // const clientAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusiness.manage%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics.edit%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics.manage.users%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics.readonly&response_type=code&client_id=596586744291-viv0dtkpvfalgec4ehotu00qv3hdnd42.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2FCallbackFromGoogle';

  const {
    setValue,
    values,
    validation: { dirty, valid, tooShort, valueMissing, patternMismatch },
    onSubmit,
    reset,
    valid: formValid,
    changed,
  } = useForm({
    defaults,
    onSubmit: async input => {
      console.log('inputs: ');
      console.log(input);
      try {
        // alert('aaaa');
        console.log('values: ');
        console.log(values);
        const response = await onboardService.createScoping({
          scopingAbn: input.abn,
          scopingBusinessName: input.name,
          scopingForm: 'gmb_auth',
        });
        console.log('response: ');
        console.log(response);
        if (response.status === 'success') {
          window.location.href = `${clientAuthUrl}&state=${response.scopingId}`; // this is what goes off to google but we want to save the scoping data first and use a unique key for the state
          // window.location.href = `${clientAuthUrl}&state=${uniqueClientId}`;  //this is what goes off to google but we want to save the scoping data first and use a unique key for the state
        }
        // now we can do the
        // await mutateReview({
        //   variables: {
        //     input,
        //   },
        // SAVE STATE
        // callback
        // alert('hell');
        // window.location.href=`www.google.com`;

        // });
        // onSuccess(listingName);
        // getReviews();
      } catch (err) {
        console.log(err);
        reset();
        throw err;
      }
    },
  });

  // //&approval_prompt=force

  // const authurl='fGenerateAuthUrl'

  return (
    <Page title="Onboarding">
      {loading ? (
        <Splash />
      ) : (
        <PageContent>
          <StyledContainer>
            <PageHeader />
            <h3>Welcome to Localsearch Authentication Tools</h3>
            <br />
            <br />
            <a href="gmbauth">Go to GMB auth</a>
            <br />
            <br />
          </StyledContainer>
        </PageContent>
      )}
    </Page>
  );
};

export default Home;
