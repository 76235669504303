const defaults = require('./defaults');

module.exports = {
  ...defaults,
  api: {
    baseUrl: 'http://localhost:3000',
  },
  gmbGrader: {
    buttonUrl:
      'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusiness.manage%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&client_id=596586744291-viv0dtkpvfalgec4ehotu00qv3hdnd42.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fgmbauth%2FCallbackFromGoogle',
  },
};
