import React from 'react';
import { themes, components, context } from 'ls-common-client';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import Layout from './Layout';
import { AppContext } from '../context';
import CustomTheme from '../theme';

const { default: theme } = themes;
const { mediaQueries } = theme;
const { GlobalStyle: CommonStyle } = components;
const { MediaContext } = context;

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 1px;
    min-height:100%;
    background: #222e35;
  }
  button {
    outline: none;
    &:active {
      outline: none;
    }
    &:not(disabled) {
      cursor: pointer;
    }
  }
`;

const customTheme = {
  ...theme,
  ...CustomTheme,
};

const App = () => (
  <MediaContext.Provider mediaQueries={mediaQueries}>
    <ThemeProvider theme={customTheme}>
      <React.Fragment>
        <CommonStyle />
        <GlobalStyle />
        <AppContext.Provider>
          <Layout />
        </AppContext.Provider>
      </React.Fragment>
    </ThemeProvider>
  </MediaContext.Provider>
);

export default App;
