const defaults = require('./defaults');

module.exports = {
  ...defaults,
  env: 'development',
  hot: false,
  api: {
    baseUrl:
      'https://4zqmju6ugb.execute-api.ap-southeast-2.amazonaws.com/development',
  },
  gmbGrader: {
    buttonUrl:
      'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusiness.manage%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&client_id=596586744291-viv0dtkpvfalgec4ehotu00qv3hdnd42.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fonboarding.development.localsearch.cloud%2Fgmbauth%2FCallbackFromGoogle',
  },
  deploy: {
    certificateArn:
      'arn:aws:acm:us-east-1:789417954668:certificate/0fa39e3f-bb67-463c-9c96-d2f9f4b28be8',
    domain: 'development.localsearch.cloud',
    subDomain: `onboarding.development.localsearch.cloud`,
    zoneID: 'Z1022601L0R4OCUTKGLN',
    logBucket: 'ls-onboarding-logs-dev',
  },
};
