const defaults = require('./defaults');

module.exports = {
  ...defaults,
  env: 'production',
  debug: false,
  mode: 'production',
  sourceMap: 'source-map',
  hot: false,
  api: {
    baseUrl:
      'https://0ywevn4ok9.execute-api.ap-southeast-2.amazonaws.com/production',
  },
  gmbGrader: {
    buttonUrl:
      'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusiness.manage%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&client_id=596586744291-viv0dtkpvfalgec4ehotu00qv3hdnd42.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fonboarding.localsearch.com.au%2Fgmbauth%2FCallbackFromGoogle',
  },
  deploy: {
    domain: 'onboarding.localsearch.com.au',
    subDomain: 'onboarding.localsearch.com.au',
    zoneID: 'Z02198382K1Y21VN40AG6',
    certificateArn:
      'arn:aws:acm:us-east-1:708891215413:certificate/ca9f8ca5-b910-4734-bf7b-f1e09a698019',
  },
};
